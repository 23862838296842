import AlyviaMossJpeg from "../../images/alyvia_moss.jpeg"
import TiffanyWongJpeg from "../../images/tiffany_wong.jpeg"
import { useState } from "react"

const AlyviaMoss = () => {
  return (
    <blockquote className="mt-10">
      <div className="max-w-3xl mx-auto text-center text-xl leading-9 font-normal text-gray-900">
        <p>
          &ldquo;After graduating from a top bootcamp in Southern California, it
          quickly became apparent to me that I was unprepared for the interview
          process, let alone a position as a software engineer. It wasn't until
          meeting the team and receiving one-on-one mentorship that I was able
          to apply what I had learned to a production level codebase and gain
          the necessary confidence to start applying for jobs.
          <br />
          <br />
          Their advice and help with interviewing and honing my technical skills
          resulted in me receiving an offer I was very pleased with. This is a
          network of great software engineers that are always available to
          answer questions and offer guidance and I am grateful to be able to
          continue to lean on them as my career progresses.&rdquo;
        </p>
      </div>
      <footer className="mt-8">
        <div className="md:flex md:items-center md:justify-center">
          <div className="md:flex-shrink-0">
            <img
              className="mx-auto h-10 w-10 rounded-full"
              src={AlyviaMossJpeg}
              alt=""
            />
          </div>
          <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
            <div className="text-base font-medium text-gray-900">
              Alyvia Moss
            </div>

            <svg
              className="hidden md:block mx-1 h-5 w-5 text-indigo-600"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M11 0h3L9 20H6l5-20z" />
            </svg>

            <div className="text-base font-medium text-gray-500">
              Software Engineer at PlayVS
            </div>
          </div>
        </div>
      </footer>
    </blockquote>
  )
}

const TiffanyWong = () => {
  return (
    <blockquote className="mt-10">
      <div className="max-w-3xl mx-auto text-center text-xl leading-9 font-normal text-gray-900">
        <p>
          &ldquo;There are 3 key steps to getting a job out of bootcamp: Finding
          an opening, getting the first round, and getting the offer. Each of
          these steps require a different process and skillset. Josh and his
          team are experts at all of these, which ultimately allowed me to get
          my first eng job at PlayVS. Plus, they made the entire recruiting
          process as fun as possible.
          <br />
          <br />
          Josh's team helped me identify opportunities that would help me grow
          in both the short and long run.They used thoughtful outreach
          strategies and their professional network that convinced recruiters to
          give me a chance. Finally, they have seen it all when it comes to
          technical interviews. They helped me hone my technical and
          communication skills so I could get the offer. &rdquo;
        </p>
      </div>
      <footer className="mt-8">
        <div className="md:flex md:items-center md:justify-center">
          <div className="md:flex-shrink-0">
            <img
              className="mx-auto h-10 w-10 rounded-full"
              src={TiffanyWongJpeg}
              alt=""
            />
          </div>
          <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
            <div className="text-base font-medium text-gray-900">
              Tiffany Wong
            </div>

            <svg
              className="hidden md:block mx-1 h-5 w-5 text-indigo-600"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M11 0h3L9 20H6l5-20z" />
            </svg>

            <div className="text-base font-medium text-gray-500">
              Software Engineer at PlayVS
            </div>
          </div>
        </div>
      </footer>
    </blockquote>
  )
}

export default function TestimonialsSection() {
  const testimonials = [<AlyviaMoss />, <TiffanyWong />]
  const [index, setIndex] = useState(0)
  const handlePrevious = () => {
    if (index === 0) {
      setIndex(testimonials.length - 1)
    } else {
      setIndex(index - 1)
    }
  }
  const handleNext = () => {
    if (index === testimonials.length - 1) {
      setIndex(0)
    } else {
      setIndex(index + 1)
    }
  }
  const CurrentTestimonial = testimonials[index]
  return (
    <section className="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24">
      <div className="relative mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
        <h2 className="text-base font-semibold tracking-wider text-indigo-600 uppercase">
          Testimonials
        </h2>

        <p className="mt-5 max-w-prose mx-auto text-2xl text-gray-500">
          We've worked with some great people in the past. Here's what they say
          about us!
        </p>
        <div className="relative w-full">
          <div className="carousel justify-center">
            <div className="carousel-item">
              {CurrentTestimonial}
              <div class="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                <button onClick={handlePrevious} class="btn btn-circle">
                  ❮
                </button>
                <button onClick={handleNext} class="btn btn-circle">
                  ❯
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
