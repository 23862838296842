import CtaSection from "../sections/Cta"
import AlternatingSection from "../sections/Features/Alternating"
import LargeScreenshotSection from "../sections/Features/LargeScreenshot"
import HeroSection from "../sections/Hero"
import TestimonialsSection from "../sections/Testimonials"

// markup
const HomePage = () => {
  return (
    <main>
      <HeroSection />
      <LargeScreenshotSection />
      <AlternatingSection />
      <TestimonialsSection />
      <CtaSection />
    </main>
  )
}

export default HomePage
