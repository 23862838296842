import MentorJpeg from "../../../images/mentor.jpeg"

export default function LargeScreenshotSection() {
  return (
    <div className="relative bg-gray-50 py-16 overflow-hidden sm:pt-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
        <div>
          <h2 className="text-base font-semibold tracking-wider text-indigo-600 uppercase">
            Experience
          </h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            Build hands-on experience
          </p>
          <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
            From take home challenges to on-site coding interviews, startups
            screen for candidates who are capable of doing the day-to-day work.
          </p>
          <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
            Gain experience working through structured projects and coding
            challenges. Through regular code reviews with seasoned engineers,
            you'll get detailed, actionable feedback on how to move your skills
            forward. With time, you'll progress in skills that prepare you for
            any coding assessment startups throw your way in the job search.
          </p>
        </div>
        <div className="mt-12">
          <img
            className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5 lg:w-8/12 mx-auto"
            src={MentorJpeg}
            alt="mentor"
          />
        </div>
      </div>
    </div>
  )
}
