import { Popup } from "react-typeform-embed"

export default function CtaSection() {
  return (
    <div className="py-12 md:py-20 lg:py-24">
      <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block">Are you ready to invest in yourself?</span>
        </h2>
        <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
          Finding a job after bootcamp is hard for many graduates. Without a
          structured system, mentorship and a network, graduates are often left
          to fend for themselves. It's common to apply to hundreds of
          interviews, facing rejection after rejection, without any signal on
          what to work on.
        </p>
        <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
          It doesn't have to be that way. With codeaway, you can have a
          structured system, mentorship and a network that will support you in
          the job search. Invest in yourself and accelerate the start of your
          new career.
        </p>
        <div className="mt-8 flex justify-center">
          <div className="inline-flex rounded-md shadow">
            <Popup id="F2SYMgcN" size={80}>
              <button className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
                Join the waitlist
              </button>
            </Popup>
          </div>
        </div>
      </div>
    </div>
  )
}
