import { Popup } from "react-typeform-embed"
import CodingPracticeJpeg from "../../../images/coding-practice.jpeg"
import ProfessionalNetworkJpeg from "../../../images/professional-network.jpeg"

export default function AlternatingSection() {
  return (
    <div className="relative bg-white overflow-hidden">
      <div className="relative">
        <div className="py-12 lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24  md:py-20 lg:py-16">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div className="mt-6">
                <h2 className="text-base font-semibold tracking-wider text-indigo-600 uppercase">
                  Support
                </h2>
                <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                  Accelerate your search with our network
                </p>
                <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
                  Our engineers have built their networks as they have worked in
                  high-growth startups. They know the hiring managers and
                  recruiters at startups, and what they are looking for.
                </p>
                <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
                  When you are ready to interview, you'll find support with
                  direct referrals to startups and introductions to people in
                  our networks. Interview confidentally and have the support
                  system to land your gig.
                </p>
                <div className="mt-6">
                  <Popup id="F2SYMgcN" size={80}>
                    <button className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700">
                      Get started
                    </button>
                  </Popup>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="md:mx-auto lg:px-0 lg:m-0 max-w-3/4 md:max-w-screen-md lg:relative lg:h-full lg:w-auto">
              <img
                className="rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 h-auto lg:translate-y-1/4"
                src={CodingPracticeJpeg}
                alt="practice-coding"
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="py-12 lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24 md:py-20 lg:py-16">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
            <div>
              <div className="mt-6">
                <h2 className="text-base font-semibold tracking-wider text-indigo-600 uppercase">
                  Mutual Success
                </h2>
                <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                  We don't make money until you get hired
                </p>
                <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
                  We're incredibly motivated to help you through this process.
                  We don’t get paid until you get hired. After you get hired,
                  you'll pay 5% of your first year salary over the next 12
                  months.
                </p>
                <div className="mt-6">
                  <Popup id="F2SYMgcN" size={80}>
                    <button className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700">
                      Get started
                    </button>
                  </Popup>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div className="md:mx-auto lg:px-0 lg:m-0 max-w-3/4 md:max-w-screen-md lg:relative lg:h-full lg:w-auto">
              <img
                className="rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 h-auto lg:translate-y-1/3"
                src={ProfessionalNetworkJpeg}
                alt="professional-network"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
